<ul class="providers-wrapper">
  @for (provider of providers$ | async; track provider) {
    <li class="provider">
      <a href="{{ provider.url }}">
        <mat-icon svgIcon="{{ providerNameMap[provider.name] ?? provider.name }}"></mat-icon>
        {{ providerNameMap[provider.name] ?? provider.name }}
      </a>
    </li>
  }
</ul>
