import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import routerConfig from '../configs/router.config';
import { WhiteLabelGuard } from '../guards/whitelabel.guard.service';
import { AccountConfirmComponent } from './account-confirm/account-confirm.component';
import { ContainerComponent } from './container/container.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { CatchTokenComponent } from '../catch-token/catch-token.component';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'login',
        title: 'auth.login.title',
        component: LoginComponent,
      },
      {
        path: 'forgot',
        title: 'auth.forgot_password.title',
        component: ForgotPasswordComponent,
      },
      {
        path: 'set-password',
        title: 'auth.set_password.title',
        component: SetPasswordComponent,
      },
      {
        path: 'verify/:token',
        title: 'auth.account_confirm.title',
        component: AccountConfirmComponent,
      },
      {
        path: 'token/:token',
        component: CatchTokenComponent,
      },
    ],
  },
  {
    path: 'register',
    title: 'auth.register.register_link_txt',
    canActivate: [WhiteLabelGuard],
    data: routerConfig.authRegister,
    component: RegisterComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
