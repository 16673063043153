import { Component, inject } from '@angular/core';
import { AuthService } from '../../shared/service/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { AuthProvidersInterface } from '../../shared/model/response.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-auth-providers',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './auth-providers.component.html',
  styleUrl: './auth-providers.component.scss',
})
export class AuthProvidersComponent {
  private authService = inject(AuthService);
  providers$: Observable<AuthProvidersInterface[]> = this.authService.getExternalProviders();

  providerNameMap: { [key: string]: string } = {
    'linkedin-openid': 'linkedin',
  };
}
