<div [cdkTrapFocusAutoCapture]="true" cdkTrapFocus class="row register-container">
  <div class="col left">
    <div class="column-wrapper">
      <div class="register__container">
        <img alt="Sembot-logo" class="register__logo" height="19" ngSrc="assets/images/register/sembot-black-big.svg" width="93" />
      </div>

      <div (mouseenter)="onHoverStart()" (mouseleave)="onHoverEnd()" class="carousel">
        <div *ngFor="let slide of slides; index as i" [ngClass]="{ active: i === currentSlide }" class="slide">
          <div class="register-text-container">
            <div class="header-box">
              <h2 class="register-title">{{ 'auth.register.slide_title.' + (i + 1) + '' | translate }}</h2>
              <div class="arrow-box">
                <div
                  (click)="changeSlide('prev')"
                  (keydown)="changeSlideWithKeys($event)"
                  class="register-arrow-left"
                  tabindex="0"
                  type="button"
                ></div>
                <div
                  (click)="changeSlide('next')"
                  (keydown)="changeSlideWithKeys($event)"
                  class="register-arrow-right"
                  tabindex="0"
                  type="button"
                ></div>
              </div>
            </div>

            <div *ngIf="'auth.register.slide_description.' + (i + 1) | translate as descriptions">
              <ng-container *ngIf="isArray(descriptions)">
                <ol *ngIf="descriptions.length > 0" class="register-description-list">
                  <li *ngFor="let description of descriptions">{{ description }}</li>
                </ol>
              </ng-container>
              <p *ngIf="!isArray(descriptions)" class="register-description">{{ descriptions }}</p>
            </div>
          </div>

          <img [src]="slide.url" alt="{{ slide.title }}" class="register-image" />
        </div>
      </div>

      <div class="brand-logos">
        <app-brand-logos></app-brand-logos>
      </div>
    </div>
  </div>
  <div class="col right">
    <form [formGroup]="form" id="registerForm">
      <div class="main-content">
        <div class="top">
          <h1 class="main-title">{{ 'auth.register.title' | translate }}</h1>
          <p class="description">
            <span>{{ 'auth.register.description' | translate }}</span>
            <span class="description-badge">{{ 'auth.register.trial' | translate }}</span>
          </p>
        </div>
        <div *ngIf="form.errors && form.errors['serverError']" class="errorMessage">
          {{ form.errors['serverError'] }}
        </div>

        <label class="form-label">{{ 'auth.register.form.fields.email.label' | translate }}</label>
        <mat-form-field appearance="outline">
          <input [formControl]="email" [placeholder]="'auth.register.form.fields.email.placeholder' | translate" matInput type="email" />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>

        <label class="form-label">{{ 'auth.register.form.fields.password.label' | translate }}</label>
        <mat-form-field appearance="outline">
          <input
            class="sentry-mask"
            [formControl]="password"
            [placeholder]="'auth.register.form.fields.password.placeholder' | translate"
            [type]="isPasswordVisible ? 'text' : 'password'"
            matInput
          />
          <button class="mat-icon-password" mat-icon-button matSuffix type="button" (click)="isPasswordVisible = !isPasswordVisible">
            <mat-icon>
              {{ isPasswordVisible ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </button>
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>

        <label class="form-label">{{ 'auth.register.form.fields.password_repeat.label' | translate }}</label>
        <mat-form-field appearance="outline">
          <input
            [formControl]="passwordConfirmation"
            [placeholder]="'auth.register.form.fields.password_repeat.placeholder' | translate"
            [type]="isPasswordConfirmationVisible ? 'text' : 'password'"
            matInput
          />

          <button
            class="mat-icon-password"
            mat-icon-button
            matSuffix
            type="button"
            (click)="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
          >
            <mat-icon>
              {{ isPasswordConfirmationVisible ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </button>
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>

        <label class="form-label">{{ 'auth.register.form.fields.source.label' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="source" placeholder="{{ 'auth.register.form.fields.source.label' | translate }}">
            <mat-option *ngFor="let source of sources" [value]="source"
              >{{ 'auth.register.form.fields.source.choices.' + source | translate }}
            </mat-option>
          </mat-select>
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>

        <mat-form-field *ngIf="source.value === 'other'" appearance="outline">
          <input [formControl]="sourceOther" [placeholder]="'auth.register.form.fields.source_other.label' | translate" matInput />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>

        <label class="form-label">{{ 'auth.register.form.fields.coupon.label' | translate }}</label>
        <mat-form-field appearance="outline">
          <input
            [formControl]="couponCtrl"
            [placeholder]="'auth.register.form.fields.coupon.placeholder' | translate"
            matInput
            name="coupon"
          />
          <app-form-field-error-display matError></app-form-field-error-display>
        </mat-form-field>

        <div class="captcha mt20">
          <re-captcha
            (errored)="captchaErrored()"
            [ngClass]="{ 'captcha-invalid': isCaptchaInvalid }"
            errorMode="handled"
            formControlName="recaptcha"
            id="recaptcha"
          ></re-captcha>

          <div *ngIf="isCaptchaInvalid" class="mat-form-error">
            <mat-error>{{ 'form_validation.error.required' | translate }}</mat-error>
          </div>
        </div>

        <mat-checkbox [formControl]="regs" class="regs mt20" color="primary">
          <span class="label">
            {{ 'auth.register.terms_conditions.accept' | translate }}
            <a [href]="termsAndconditionsUrl" target="_blank">{{ 'auth.register.terms_conditions.link' | translate }}</a>
            {{ 'auth.register.terms_conditions.and' | translate }}
            <a [href]="privacyPolicyUrl" target="_blank">{{ 'auth.register.terms_conditions.privacy' | translate }}</a
            >.
          </span>
          <div *ngIf="regs.touched && !regs.value" class="mat-form-error">
            <mat-error>{{ 'form_validation.error.required' | translate }}</mat-error>
          </div>
        </mat-checkbox>

        <mat-checkbox [formControl]="allowBrandUse" class="regs" color="primary">
          <span class="label">
            {{ 'auth.register.marketing_consent' | translate }}
          </span>
        </mat-checkbox>
      </div>
      <app-button
        (click)="onSubmit()"
        [fullWidth]="true"
        [id]="'registerSubmitBtn'"
        [loading]="isLoading"
        [text]="'auth.register.form.submit_btn_txt' | translate"
        class="button-register"
        color="primary"
        size="large"
        type="submit"
      ></app-button>

      <div class="bottom">
        <div class="bar">
          {{ 'auth.register.have_account_info' | translate }}
          <strong appRouter="/">
            {{ 'auth.register.login_link_txt' | translate }}
          </strong>
        </div>
      </div>
    </form>
    <app-auth-change-language></app-auth-change-language>
  </div>
</div>
