import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BehaviorSubject, Subject, interval, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { PasswordValidation } from 'src/app/shared/validators/password-validation.service';
import { environment } from 'src/environments/environment';
import { FormApiValidationError } from '../../shared/model/errors/formApiError.model';
import { SlideInterface } from '../../shared/model/slider.model';
import { passwordStrengthValidator } from '../../shared/helpers/utils';

const SUPPORTED_TYPES = ['audit', 'beta'];

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  accountType: string = ''; // @TODO: enum
  form!: UntypedFormGroup;
  isLoading = false;
  isPasswordVisible = false;
  isPasswordConfirmationVisible = false;
  currentSlide = 0;
  hoverPaused = new BehaviorSubject<boolean>(false);
  protected slides: SlideInterface[] = [
    { url: 'assets/images/register/visibility-monitor.png', title: 'visibility-monitor' },
    { url: 'assets/images/register/feed-optimiser.png', title: 'feed-optimiser' },
    { url: 'assets/images/register/campaign-generator.png', title: 'campaign-generator' },
    { url: 'assets/images/register/price-monitor.png', title: 'price-monitor' },
  ];

  protected sources: string[] = [
    'product_ads',
    'google_ads',
    'google_search',
    'blog',
    'blog_sembot',
    'sales_contact',
    'your_agency',
    'influencer',
    'other',
  ];

  protected readonly Array = Array;
  private destroy$: Subject<void> = new Subject();
  private authService = inject(AuthService);
  private formBuilder = inject(UntypedFormBuilder);
  private gtmService = inject(GoogleTagManagerService);
  private notificationService = inject(NotificationService);
  private route = inject(ActivatedRoute);
  private routerService = inject(RouterService);
  private translationService = inject(TranslationService);
  privacyPolicyUrl: string = this.translationService.commonUrls().privacyPolicy;
  termsAndconditionsUrl: string = this.translationService.commonUrls().termsAndconditions;

  get email() {
    return this.form.get('email') as UntypedFormControl;
  }

  get password() {
    return this.form.get('password') as UntypedFormControl;
  }

  get passwordConfirmation() {
    return this.form.get('password_confirmation') as UntypedFormControl;
  }

  get regs() {
    return this.form.get('regs') as UntypedFormControl;
  }

  get allowBrandUse() {
    return this.form.get('allow_brand_use') as UntypedFormControl;
  }

  get couponCtrl() {
    return this.form.get('coupon') as UntypedFormControl;
  }

  get recaptchaCtrl() {
    return this.form.get('recaptcha') as UntypedFormControl;
  }

  get source() {
    return this.form.get('source') as UntypedFormControl;
  }

  get sourceOther() {
    return this.form.get('sourceOther') as UntypedFormControl;
  }

  get isCaptchaInvalid() {
    const { dirty, invalid, touched } = this.recaptchaCtrl;
    return invalid && (dirty || touched);
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  ngOnInit() {
    const accountType = this.route.snapshot.queryParamMap.get('type');
    accountType && SUPPORTED_TYPES.includes(accountType) && (this.accountType = accountType);

    this.form = this.formBuilder.group(
      {
        email: ['', [Validators.email, Validators.required, Validators.minLength(5)]],
        password: ['', [Validators.required, passwordStrengthValidator]],
        password_confirmation: ['', Validators.required],
        regs: [false, Validators.requiredTrue],
        source: [null],
        sourceOther: [null],
        coupon: [null],
        recaptcha: ['', [Validators.required]],
        allow_brand_use: [false],
      },
      {
        validator: PasswordValidation.matchPassword,
      },
    );

    this.startAutoSlideChange();
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.notificationService.error('invalid_form');
      return;
    }

    this.isLoading = true;
    const { email, password, passwordConfirmation, regs, source, sourceOther, couponCtrl, allowBrandUse } = this;
    this.authService
      .register(
        email.value,
        password.value,
        passwordConfirmation.value,
        regs.value,
        this.accountType,
        sourceOther.value ?? source.value,
        couponCtrl.value,
        allowBrandUse.value,
      )
      .pipe(
        catchError((er: FormApiValidationError) => this.handleErrorOnRegisterRequest(er)),
        tap(() => this.sendDataForGTM()),
        switchMap(() => this.authService.login(email.value, password.value)),
      )
      .subscribe(() => {
        this.notificationService.success('register_account');
        this.routerService.navigate(routerConfig.home);
      });
  }

  captchaErrored(): void {
    this.notificationService.error('captcha');
  }

  changeSlide(direction: 'next' | 'prev') {
    if (direction === 'next') {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    } else {
      this.currentSlide = this.currentSlide === 0 ? this.slides.length - 1 : this.currentSlide - 1;
    }
  }

  changeSlideWithKeys(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      this.changeSlide('next');
    } else if (event.key === 'ArrowLeft') {
      this.changeSlide('prev');
    }
  }

  onHoverStart() {
    this.hoverPaused.next(true);
  }

  onHoverEnd() {
    this.hoverPaused.next(false);
  }

  startAutoSlideChange() {
    interval(5000)
      .pipe(
        switchMap(() => this.hoverPaused),
        tap((paused) => {
          if (!paused) {
            this.changeSlide('next');
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private sendDataForGTM() {
    if (!environment.production) {
      return;
    }
    const gtmTag = {
      event: 'sign_up',
    };
    this.gtmService.pushTag(gtmTag);
  }

  private handleErrorOnRegisterRequest(er: FormApiValidationError) {
    this.isLoading = false;
    if (er.setOnForm) {
      er.setOnForm(this.form);
      er.notify();
    }
    return throwError(er);
  }
}
