<div [cdkTrapFocusAutoCapture]="true" cdkTrapFocus class="row">
  <div class="col">
    <div class="set-password__container">
      <div class="top">
        <h1 class="set-password__title">{{ 'auth.set_password.title' | translate }}</h1>
      </div>

      <form (ngSubmit)="onSubmit()" [formGroup]="form" id="form">
        <div class="main-content">
          <label class="set-password__form-label">{{ 'auth.set_password.form.fields.password.label' | translate }}</label>
          <mat-form-field appearance="outline">
            <input
              class="sentry-mask"
              [formControl]="password"
              [placeholder]="'auth.set_password.form.fields.password.placeholder' | translate"
              [type]="isPasswordVisible ? 'text' : 'password'"
              matInput
              required
            />
            <button class="mat-icon-password" mat-icon-button matSuffix type="button" (click)="isPasswordVisible = !isPasswordVisible">
              <mat-icon>
                {{ isPasswordConfirmationVisible ? 'visibility_off' : 'visibility' }}
              </mat-icon>
            </button>
            <app-form-field-error-display matError></app-form-field-error-display>
          </mat-form-field>

          <label class="set-password__form-label">{{ 'auth.set_password.form.fields.password_confirmation.label' | translate }}</label>
          <mat-form-field appearance="outline">
            <input
              [formControl]="passwordConfirmation"
              [placeholder]="'auth.set_password.form.fields.password_confirmation.placeholder' | translate"
              [type]="isPasswordConfirmationVisible ? 'text' : 'password'"
              matInput
              required
            />

            <button
              class="mat-icon-password"
              mat-icon-button
              matSuffix
              type="button"
              (click)="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
            >
              <mat-icon>
                {{ isPasswordConfirmationVisible ? 'visibility_off' : 'visibility' }}
              </mat-icon>
            </button>
            <app-form-field-error-display matError></app-form-field-error-display>
          </mat-form-field>
        </div>

        <app-button
          [fullWidth]="true"
          [loading]="isLoading"
          [text]="'auth.set_password.form.submit_btn_txt' | translate"
          color="primary"
          marginSize="0px"
          size="large"
          type="submit"
        ></app-button>
      </form>
    </div>
    <app-brand-logos></app-brand-logos>
  </div>
</div>
